import { FC, useCallback, useEffect, useState } from "react"
import { Dropdown, Nav, Navbar, NavItem } from "react-bootstrap"
import { Link, NavLink } from "react-router-dom"
import { FaBell, FaUserAlt } from 'react-icons/fa';
import { AiFillQuestionCircle, AiOutlineClose, AiOutlineCloseCircle, AiOutlineInfoCircle, AiOutlineMenu, AiOutlineWarning } from 'react-icons/ai'
import { MdDeleteForever } from 'react-icons/md';
import { IoClose } from 'react-icons/io5'
import useWebSocket from 'react-use-websocket'

import useDetectSubdomainOrPublic from "../../../../../hooks/useDetectSubdomainOrPublic";
import { selectAccessToken, signOut } from "../../../../../redux/reducers/auth/authSlice";
import { notificationsApi, useGetPublicNotificationsQuery, useGetTenantNotificationsQuery, usePublicNotificationActionMutation, useTenantNotificationActionMutation } from "../../../../../api/notificationsAPI";
import { authApi, useLogoutMutation } from "../../../../../api/authAPI";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { settingsApi, useGetTenantDataQuery } from "../../../../../api/settingsAPI";
import { TNotification } from "../../../../../api/api.types";
import { affiliateApi } from "../../../../../api/affiliateAPI";
import { categoriesApi } from "../../../../../api/categoriesAPI";
import { integrationsApi } from "../../../../../api/integrationsAPI";
import { jobsApi } from "../../../../../api/jobsAPI";
import { ordersApi } from "../../../../../api/ordersAPI";
import { paymentsApi } from "../../../../../api/paymentsAPI";
import { productsApi } from "../../../../../api/productsAPI";
import { statsApi } from "../../../../../api/statsAPI";

import LogoIconBlue from '../../../../assets/images/logo-icon-blue.png'

type TDashboardHeaderProps = {
    expanded: boolean,
    showSidebar?: boolean,
    setShowSidebar?: (value: boolean) => void;
}

const DashboardHeader: FC<TDashboardHeaderProps> = ({ expanded, showSidebar, setShowSidebar }) => {
    const [isPublic, subdomain] = useDetectSubdomainOrPublic()
    const [logout] = useLogoutMutation()
    const [tenantNotificationAction] = useTenantNotificationActionMutation()
    const [publicNotificationAction] = usePublicNotificationActionMutation()
    const [showNotifications, setShowNotifications] = useState(false)
    const [notifications, setNotifications] = useState<TNotification[]>([])
    const [unreadedNotifications, setUnreadedNotifications] = useState(0)
    const accessToken = useAppSelector(selectAccessToken)
    const dispatch = useAppDispatch()

    const socketUrl = `wss://${window.location.hostname}/wss/notifications/${isPublic ? 'public/' : 'tenant/'}?token=${accessToken}`;
    const { data } = useGetTenantDataQuery(subdomain, { skip: isPublic || !subdomain })
    const { data: tenantNotifications } = useGetTenantNotificationsQuery(undefined, { skip: isPublic })
    const { data: publicNotifications } = useGetPublicNotificationsQuery(undefined, { skip: isPublic ? false : true })

    const handleReadNotifications = useCallback((list: number[]) => {
        if (isPublic) {
            publicNotificationAction({ ids_list: list, action: 'READ' })
        } else {
            tenantNotificationAction({ ids_list: list, action: 'READ' })
        }
    }, [tenantNotificationAction, isPublic, publicNotificationAction])

    const handleDeleteNotification = (id: number) => {
        if (isPublic) {
            publicNotificationAction({ ids_list: [id], action: 'DELETE' })
        } else {
            tenantNotificationAction({ ids_list: [id], action: 'DELETE' })
        }
    }

    const hideNotifications = () => {
        setShowNotifications(false)
    }

    const handleShowNotifications = () => {
        setShowNotifications((prev) => {
            return !prev
        })
    }

    const handleLogoutUser = () => {
        const baseUrl = process.env.REACT_APP_PUBLIC_TENANT_URL
        logout()
        dispatch(signOut())
        dispatch(authApi.util.resetApiState());
        dispatch(affiliateApi.util.resetApiState());
        dispatch(categoriesApi.util.resetApiState());
        dispatch(integrationsApi.util.resetApiState());
        dispatch(notificationsApi.util.resetApiState());
        dispatch(jobsApi.util.resetApiState());
        dispatch(ordersApi.util.resetApiState());
        dispatch(paymentsApi.util.resetApiState());
        dispatch(productsApi.util.resetApiState());
        dispatch(settingsApi.util.resetApiState());
        dispatch(statsApi.util.resetApiState());

        if (!isPublic && baseUrl) {
            window.location.href = baseUrl
        }
    }

    const { sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            if (isPublic) {
                sendJsonMessage({
                    action: "subscribe_to_notification_activity",
                    request_id: new Date().getTime(),
                    tenant_id: 'None',
                })
            }
        },
        onMessage: (e) => {
            const message = JSON.parse(e.data);
            setNotifications((prev) => {
                return [...prev, message]
            })
        },
    })

    useEffect(() => {
        if (showNotifications) {
            let list: number[] = []

            notifications.forEach((el) => {
                if (el.checked === false) {
                    list.push(el.id)
                }
            })

            if (list.length > 0) {
                setTimeout(() => {
                    handleReadNotifications(list)
                }, 1500)
            }
        }
    }, [showNotifications, notifications, handleReadNotifications])

    useEffect(() => {
        if (notifications.length > 0) {
            let count = 0

            notifications.forEach((el) => {
                if (el.checked === false) {
                    count = count + 1
                }
            })

            setUnreadedNotifications(count)
        } else {
            setUnreadedNotifications(0)
        }
    }, [notifications])

    useEffect(() => {
        if (tenantNotifications) {
            setNotifications(tenantNotifications)
        } else if (publicNotifications) {
            setNotifications(publicNotifications)
        }
    }, [tenantNotifications, publicNotifications])

    useEffect(() => {
        if (data) {
            sendJsonMessage({
                action: "subscribe_to_notification_activity",
                request_id: new Date().getTime(),
                tenant_id: data.id,
            })
        }
    }, [data, sendJsonMessage, isPublic])

    return (
        <Navbar expanded={expanded} className='user-header px-2 py-0' >
            <Navbar.Brand className='col-xl-3 col-lg-3 m-0 d-flex align-items-center'>
                {showSidebar ?
                    <AiOutlineClose className='sidebar-img ms-3' fill='white' size={25} onClick={() => setShowSidebar && setShowSidebar(!showSidebar)} />
                    :
                    <AiOutlineMenu className='sidebar-img ms-3' fill='white' size={25} onClick={() => setShowSidebar && setShowSidebar(!showSidebar)} />}
                <div className='user-header-logo'>
                    <img width={80} src={LogoIconBlue} alt="logo" />
                    <span className='user-header-logo-text'>Cloud Order</span>
                </div>
            </Navbar.Brand >
            <div className='col-xl-9 col-lg-9 justify-content-between'>
                <Nav className='col-xl-7 col-lg-7 w-100 d-flex align-items-center justify-content-end'>
                    <NavLink className='me-3' to="/">
                        <AiFillQuestionCircle fill='white' size={25} />
                    </NavLink>
                    <NavItem className='me-1'>
                        <div className="cursor-pointer position-relative" onClick={handleShowNotifications}>
                            <FaBell fill='white' size={25} />
                            {unreadedNotifications > 0 && (
                                <div className="notifications-count-block">
                                    {unreadedNotifications > 9 ? '9+' : unreadedNotifications}
                                </div>
                            )}
                        </div>
                        {showNotifications && (
                            <div className='notifications-container'>
                                <div className='notifications-header'>
                                    <span className='notifications-title'>Notifications</span>
                                    <IoClose className='cursor-pointer' size={22} onClick={hideNotifications} />
                                </div>
                                <div className='notification-body'>
                                    {notifications && notifications.length > 0 ? notifications.slice(0).reverse().map((el) => (
                                        <div id={`notification-${el.id}`} key={el.id} className={el.checked ? 'notification-block' : 'notification-block notification-unchecked'}>
                                            <div className='notification-info'>
                                                <div>
                                                    {el.kind === 'Info' ? (
                                                        <AiOutlineInfoCircle fill='rgba(66, 147, 208)' className='notification-icon' size={21} />
                                                    ) : el.kind === 'Warning' ? (
                                                        <AiOutlineWarning fill='#ffcc00' className='notification-icon' size={21} />
                                                    ) : (
                                                        <AiOutlineCloseCircle fill='#ff3333' className='notification-icon' size={21} />
                                                    )}
                                                </div>
                                                <div className='notification-text'>{el.text}</div>
                                            </div>
                                            <MdDeleteForever onClick={() => handleDeleteNotification(el.id)} className="cursor-pointer" fill='#333333' size={23} />
                                        </div>
                                    )) : (
                                        <div className="d-flex h-100 justify-content-center align-items-center">There are no notifications now</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </NavItem>
                    <Dropdown>
                        <Dropdown.Toggle className='user-header-dropdown' id="dropdown-basic">
                            <div className='navigation-user-menu'>
                                <FaUserAlt fill='white' size={20} />
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='user-header-dropdown-menu'>
                            <Dropdown.Item className='p-0' as='div'>
                                <Link className='d-block w-100 px-3 py-1' to="/dashboard/settings-users/">Settings</Link>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogoutUser}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </div>
        </Navbar >
    )
}

export default DashboardHeader